import { BaseEntity } from './BaseEntity';

export enum CooperationStatus {
  OPENING = 'opening',
  SOLVED = 'solved'
}

export interface Cooperation extends BaseEntity {
  subject: string
  name: string
  email: string
  phone: string
  message: string
  attachment: string
  attachmentLink: string
  status: CooperationStatus
}

export type PartialCooperation = Partial<Cooperation>;
