
import { defineComponent, ref, unref, toRefs, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { getCooperation } from '@/services/api';
import { useI18n } from 'vue-i18n';


import {
  PartialCooperation,
  CooperationStatus
} from '@/interfaces/Cooperation';

const DEFAULT_FORM_VALUES: PartialCooperation & {} = {
  subject: '',
  name: '',
  email: '',
  phone: '',
  message: '',
  attachment: '',
  status: CooperationStatus.OPENING
};

export default defineComponent({
  setup() {
    const cooperationId = useRoute().params.id as string;
    const page = useRoute().query.page as string;
    const data = ref(DEFAULT_FORM_VALUES);
    const { t } = useI18n();


    onMounted(async() => {
      const res = await getCooperation({ cooperationId });
      data.value = res.data;
    });

    return {
      data,
      t,
      page
    };
  }
});
